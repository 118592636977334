// extracted by mini-css-extract-plugin
export var actions = "styles-module--actions--2ada8";
export var container = "styles-module--container--44173";
export var credit = "styles-module--credit--3d2d2";
export var credit_content = "styles-module--credit_content--6073b";
export var credit_content_text = "styles-module--credit_content_text--b0d44";
export var error = "styles-module--error--e18fd";
export var gatsbyImageWrapper = "styles-module--gatsby-image-wrapper--6e85a";
export var section = "styles-module--section--4b886";
export var section_paused = "styles-module--section_paused--ebbd0";
export var section_paused_content = "styles-module--section_paused_content--42e72";
export var side = "styles-module--side--f6781";
export var sideInfo = "styles-module--side--info--26c6c";
export var terms = "styles-module--terms--c2522";
import { useState, useCallback, useContext } from "react";
import axios from "axios";
import { navigate } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import { isBrowser } from "../../../context/helpers";
import { useALError, withALErrorBoundary } from "../../../helpers/ErrorBoundary/ALErrorBoundary";
import { useAuth } from "../../../context/AuthProvider";
import { LuxeContext } from "../../../context/LuxeProvider";
import { useTracking } from "../../../context/Tracking";
import useDeviceDetect from "../../../hooks/useDeviceDetect";

import { ALLink, ALButton } from "../../ALComponents";

import CancelReasons from "./cancellationPage/CancelReasons";
import PauseSubscriptionModal from "./PauseSubscriptionModal";

import * as Styles from "./cancellationPage/styles.module.scss";

function CancellationPage() {
  const { luxe, updateSubscriptionStatus, updateSubscriptionNextBillingDate } =
    useContext(LuxeContext);
  const { accountDetails } = useAuth();
  const { trackSubscriptionCancel, trackSubscriptionPause } = useTracking();
  const { isMobile } = useDeviceDetect();
  const { sendReport } = useALError();

  const [cancelOption, setCancelOption] = useState("");
  const [cancelText, setCancelText] = useState("");
  const [agree, setAgree] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isPauseModalOpen, setIsPauseModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const onTextChange = (e) => {
    setCancelText(e.target.value);
    setErrorMessage("");
  };

  const onChangeAgree = () => {
    setAgree(!agree);
    setErrorMessage("");
  };

  const cancelSubscription = useCallback(async () => {
    setIsLoading(true);
    if (cancelOption === "") {
      setIsLoading(false);
      setErrorMessage("Please select a reason for cancellation");
      return;
    }

    if (agree === false) {
      setIsLoading(false);
      setErrorMessage("Please agree to the terms");
      return;
    }

    const reason = cancelText ? `${cancelOption}: ${cancelText}` : cancelOption;

    try {
      const res = await axios.post(
        "/.netlify/functions/subscription",
        {},
        {
          params: {
            cid: accountDetails.id.replace("gid://shopify/Customer/", ""),
            email: accountDetails.email,
            action: "CANCEL",
            reason,
          },
        }
      );

      if (res.status === 200) {
        const cancelledSubscription = await axios.get(`/.netlify/functions/subscription`, {
          params: {
            cid: accountDetails.id.replace("gid://shopify/Customer/", ""),
            email: accountDetails.email,
          },
        });
        if (cancelledSubscription.status === 200) {
          setErrorMessage("");
          updateSubscriptionNextBillingDate(
            cancelledSubscription.data.membership.next_billing_date
          );
          updateSubscriptionStatus(cancelledSubscription.data.membership.status);
          trackSubscriptionCancel(reason);
          setIsLoading(false);
          navigate("/ana-luisa-luxe/cancel/confirm");
        }
      }
    } catch (e) {
      setErrorMessage("Something went wrong, please try again");
      setIsLoading(false);

      if (isBrowser && window.exponea) {
        const dataBloomreach = {
          type: "error",
          action: "cancel",
          cid: accountDetails?.id || "",
          email: accountDetails?.email || "",
          account: JSON.stringify(accountDetails),
          reason,
          agree,
          error_message: e.message,
        };
        window.exponea.track("luxe_action", dataBloomreach);
      }

      sendReport(e, {
        name: "MembershipDetailsCancelSubscription",
        priority: "P1",
      });
    }
  }, [
    accountDetails,
    agree,
    cancelOption,
    cancelText,
    trackSubscriptionCancel,
    updateSubscriptionStatus,
    sendReport,
    updateSubscriptionNextBillingDate,
  ]);

  const onPauseSubscription = useCallback(
    async (timeToPause) => {
      setIsPauseModalOpen(true);

      try {
        const res = await axios.post(
          "/.netlify/functions/subscription",
          {},
          {
            params: {
              cid: accountDetails.id.replace("gid://shopify/Customer/", ""),
              email: accountDetails.email,
              action: "PAUSE",
              period: timeToPause,
            },
          }
        );

        if (res.status === 200) {
          const pausedSubscription = await axios.get(`/.netlify/functions/subscription`, {
            params: {
              cid: accountDetails.id.replace("gid://shopify/Customer/", ""),
              email: accountDetails.email,
            },
          });
          if (pausedSubscription.status === 200) {
            updateSubscriptionNextBillingDate(pausedSubscription.data.membership.next_billing_date);
            updateSubscriptionStatus(pausedSubscription.data.membership.status);
            trackSubscriptionPause(timeToPause);
          }
        }
      } catch (e) {
        sendReport(e, {
          name: "MembershipDetailsSkipSubscription",
          priority: "P3",
        });
      }
    },
    [
      accountDetails,
      sendReport,
      trackSubscriptionPause,
      updateSubscriptionStatus,
      updateSubscriptionNextBillingDate,
    ]
  );

  if (!accountDetails && isBrowser) {
    navigate("/?auth=login&auth-redirect=https://www.analuisa.com/ana-luisa-luxe/cancel");
  }

  return (
    <div className={Styles.container}>
      <h2 className="h2 h2--bold">
        Are you sure you want to lose your membership perks, {accountDetails?.firstName}?
      </h2>

      <div className={Styles.credit}>
        <div className={Styles.credit_content}>
          <p className="h3 h3--bold">${luxe.subscriptionCredit} credit</p>

          <p className={`${Styles.credit_content_text} subtext`}>
            You will no longer accrue credit
          </p>
        </div>
      </div>

      <div className={Styles.section}>
        {isMobile ? (
          <StaticImage
            src="../../../images/rewards/what-youll-be-missing-2024_Mobile.jpg"
            alt="What you'll be missing"
            className={Styles.side}
          />
        ) : (
          <StaticImage
            src="../../../images/rewards/what-youll-be-missing-2024.jpg"
            alt="What you'll be missing"
            className={Styles.side}
          />
        )}

        {!isMobile && (
          <div className={`${Styles.side} ${Styles.sideInfo}`}>
            <h2 className="h2 h2--bold awesome">Pause instead?</h2>
            <ul>
              <li className="subtext">
                <p>
                  <span className="subtext">
                    Press pause for up to 3 months – you’ll be charged again as soon as you’re ready
                    to restart.
                  </span>
                </p>
              </li>
            </ul>

            <ALButton size="large" variant="secondary" onClick={() => setIsPauseModalOpen(true)}>
              Pause Membership
            </ALButton>
          </div>
        )}
      </div>

      {isMobile && (
        <div className={`${Styles.section} ${Styles.section_paused}`}>
          <StaticImage
            src="../../../images/rewards/pause-cancel_Mobile.jpg"
            alt="Pause instead?"
            style={{ "--border-md": "0px" }}
          />
          <div className={Styles.section_paused_content}>
            <h2 className="h2 h2--bold awesome">Pause instead?</h2>
            <p className="subtext">
              Press pause for up to 3 months – you’ll be charged again as soon as you’re ready to
              restart.
            </p>
          </div>

          <ALButton size="large" variant="secondary" onClick={() => setIsPauseModalOpen(true)}>
            Pause Membership
          </ALButton>
        </div>
      )}

      <div className={Styles.section}>
        {!isMobile && (
          <StaticImage
            src="../../../images/rewards/why_leaving.jpeg"
            alt="why are you leaving?"
            className={Styles.side}
          />
        )}

        <div className={`${Styles.side} ${Styles.sideInfo}`}>
          <h2 className="h2 h2--bold h2--uc">Why are you leaving?</h2>

          <CancelReasons
            cancelOption={cancelOption}
            setCancelOption={setCancelOption}
            setErrorMessage={setErrorMessage}
          />

          <textarea
            id="cancel-text"
            name="cancel-text"
            rows={3}
            maxLength="140"
            placeholder="Please add an explanation"
            onChange={onTextChange}
          />
        </div>
      </div>

      <div className={Styles.terms}>
        <label htmlFor="terms">
          <input
            type="checkbox"
            id="terms"
            value="terms"
            checked={!!agree}
            onChange={onChangeAgree}
          />
          <span>I understand that by proceeding, I will forfeit future membership credits.</span>
        </label>
      </div>

      {errorMessage && <p className={Styles.error}>{errorMessage}</p>}

      <div className={Styles.actions}>
        <ALButton
          size="large"
          variant="primary"
          onClick={cancelSubscription}
          isLoading={isLoading}
          disabled={isLoading}
        >
          Cancel membership
        </ALButton>

        <ALLink to="/ana-luisa-luxe">
          <ALButton size="large" variant="secondary">
            I changed my mind
          </ALButton>
        </ALLink>
      </div>

      <PauseSubscriptionModal
        isOpen={isPauseModalOpen}
        onAction={onPauseSubscription}
        onClose={() => setIsPauseModalOpen(false)}
      />
    </div>
  );
}

export default withALErrorBoundary({
  name: "CancellationPage",
  priority: "P3",
})(CancellationPage);
